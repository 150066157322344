import React from 'react';
import { useRouter } from 'next/router';
import styles from './giftsPartners.module.sass';
import { Text } from '../Text';
import { Image } from '../Image/Image';

const gift = [
  { photo: '/giftPartners/giftOne.jpg' },
  { photo: '/giftPartners/giftTwo.jpg' },
  { photo: '/giftPartners/giftThree.jpg' },
  { photo: '/giftPartners/giftFour.jpg' },
  { photo: '/giftPartners/giftFive.jpg' },
];

export const GiftsPartners = () => {
  const router = useRouter();

  return (
    <div className={styles.wrapper}>
      <div>
        <Text variant="heading-1">Подарки от партнеров</Text>
      </div>
      <a
        href={
          router.route === '/sea'
            ? '/novogodniy-keshbek-za-pokupku-kruizov?type=sea'
            : '/novogodniy-keshbek-za-pokupku-kruizov'
        }
      >
        <div className={styles.giftPhoto}>
          {gift.map(i => (
            <img
              src={i.photo}
              alt=""
              width={206}
              height={160}
              className={styles.giftImage}
            />
          ))}
        </div>
      </a>
    </div>
  );
};
